import React from "react";

import { Button } from "../../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {getPwaNavigation, goToNextPage, goToPrevPage, setPage} from "../../../store/reducers/pwaFormReducer";
import { MAX_PAGE_NUMBER } from "../data";
import styles from "./PagesNavigation.module.scss";
import { useLocation } from "react-router-dom";
import { ListOfErrors } from "../ListOfErrors/ListOfErrors";
import { useFormikContext } from "formik";
import { PwaFormValues } from "../helpers/formikHelpers";

type Props = {
  nexButtonDisabled: boolean
}
export const PagesNavigation: React.FC<Props> = ({
  nexButtonDisabled,
}) => {
  const dispatch = useAppDispatch();
  const { currentPage } = useAppSelector(getPwaNavigation);
  const location = useLocation();
  const { values, errors } = useFormikContext<PwaFormValues>();

  const editPage = location.pathname.startsWith('/edit');
  const submitButton = currentPage === MAX_PAGE_NUMBER || editPage;

  return (
    <>
      <div className={styles.wrapper}>
        {submitButton && !!values.name && <ListOfErrors/>}
        {currentPage !== 0 && <Button
            onClick={() => dispatch(goToPrevPage())}
            appear="outline"
        >Назад</Button>}
        <Button
          type={submitButton ? 'submit' : 'button'}
          onClick={(e) => {
            if (!submitButton) {
              e.preventDefault();
              dispatch(goToNextPage());
                // dispatch(setPage(1))
            }
          }}
          disabled={nexButtonDisabled || (editPage && Object.keys(errors).length !== 0)}
        >{editPage ? 'Сохранить'
          : currentPage === MAX_PAGE_NUMBER ? 'Отправить' : 'Дальше'}</Button>
      </div>

    </>
  );
};
