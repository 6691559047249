import { FormikHelpers } from "formik";
import * as Yup from 'yup';

import { Application, Cloak, Comment, PwaCreate, PwaDataGet, Tracker, } from "../../../types/api";
import { sendPwa, updatePwa } from "../../../api/pwa";
import { formatErrors } from "../../../helpers/globalFormikHelpers";
import { convertImageToBase64 } from "../../../helpers/formatData";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React from "react";
import {tabsArray} from "../data";


export type PwaFormValues = {
  name: string,
  status?: string,
  subdomain?: {
    address: string
  },
  tracker: Tracker,
  cloak: Cloak,
  application: Application,
  vertical?: {
    template: string,
    vertical: string,
    preview_template?: number,
  },
  comments: (Comment & { reply: Comment })[],
  preland?: number,
  serverError?: any,
}

export const pwaFormInitialValues: PwaFormValues = {
  name: "",
  subdomain: {
    address: ""
  },
  vertical: {
    template: "new_play_market",
    vertical: "gambling",
  },
  tracker: {
    offer_url: "",
    traffic_back: "",
  },
  cloak: {
    white_url: "",
    status: "Filter",
    type: "facebook",
    countries: [],
    whitepage_type: "",
  },
  application: {
    name: "Your name",
    add_to_wishlist: "Добавить в избранное",
    editors_choice: "Выбор редактора",
    available_devices: "Это приложение доступно для всех ваших устройств.",
    language: "",
    author: "Gold inc",
    category: "Игры",
    category_text: "Категория",
    advertising: "Без рекламы",
    age: 18,
    rating_text: "Рейтинг",
    reviews_text: "Отзывов",
    size: 10.6,
    size_text: "Размер",
    age_text: "Возраст",
    install: "Установить",
    installation: "Установка...",
    download: "Загрузить",
    open: "Открыть",
    description: "Приложение для Android",
    rating: 4.9,
    hide_reviews_text: "Скрыть рецензии",
    hide: "Меньше",
    reviews_count: 135,
    total: "Всего",
    reviews: "Оценки и отзывы",
    all_reviews_text: "Все обзоры",
    five_star: 100,
    four_star: 11,
    three_star: 1,
    two_star: 0,
    one_star: 1,
    additional_information: "Дополнительная информация",
    developer: "Разработчик",
    updated: "Обновлено",
    update_date: "11-02-2024",
    installs: "Установок",
    installs_count: "120K+",
    current_version: 1.0,
    current_version_text: "Текущая версия",
    cookies_request: "Вы согласны с использованием файлов cookies?",
    cookies_accept: "Согласен",
    cookies_reject: "Не согласен",
    about: "О приложении",
    read_more: "Подробнее...",
    whats_new_text: "Что нового?",
    whats_new: "Бонус 300 FS",
    developer_contact: "Контакт с разработчиком",
    data_safety: "Безопасность данных",
    data_share: "Данные не передаются третьим сторонам",
    data_collect: "Данные не собираются",
    security_about: "В этом разделе разработчики могут указать, как приложения собирают и используют данные.",
    data_see_details: "Смотреть детали",
    security_info: "Нет информации",
    review_helpful: "людей сочли этот отзыв полезным",
    find_this_helpful: "Вы нашли это полезным?",
    helpful_yes: "Да",
    helpful_no: "Нет",
  },
  comments: [],
}


export const PwaFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Слишком короткое!')
    .max(400, 'Слишком длинное!')
    .required('Введите имя'),
  subdomain: Yup.object().shape({
    address: Yup.string()
      .matches(/^[a-zA-Z0-9-]*$/, "Cубдомен не должен содержать других символов кроме тире"),
  }).notRequired(),
  tracker: Yup.object().shape({
    offer_url: Yup.string()
      .min(1, 'Слишком короткое!')
      .max(1000, 'Слишком длинное!')
      .matches(/^https:/, "Ссылка должна начинаться с https:")
      .matches(/\{tds_clickid}/, "Ссылка должна содержать {tds_clickid}")
      .required('Введите ссылку на оффер'),
  }),
  vertical: Yup.object().shape({
    template: Yup.string().required('Выберите вертикаль'),
  }).required('Выберите вертикаль'),
  application: Yup.object().shape({
    rating: Yup.number().max(5, 'Максимум 5'),
  }),
  comments: Yup.array().of(
    Yup.object().shape({
      author: Yup.string()
        .min(1, 'Слишком короткое!')
        .max(400, 'Слишком длинное!')
        .required('Введите автора'),
      text: Yup.string()
        .min(1, 'Слишком короткое!')
        .required('Введите текст'),
      rating: Yup.number()
        .max(5, 'Максимум 5'),
    }),
  ),
});


export const pwaFormSubmitHandler = async (
    values: PwaFormValues,
    formikHelpers: FormikHelpers<PwaFormValues>,
    setCreatePwaPending: React.Dispatch<React.SetStateAction<boolean>>,
    delaySetCreatePwaPending: (value: boolean) => void,
    successUpdateData: () => void,
    setPageContent: (index: number) => void,
    id?: number,
  ) => {
  const cloak = { ...values.cloak };
  cloak.countries = [...new Set(cloak.countries)];

  const pwaData: PwaCreate = {
    name: values.name,
    subdomain: values.subdomain,
    tracker: values.tracker,
    cloak,
    application: values.application,
    vertical: values.vertical && {
      preview_template: values.vertical.preview_template!,
      template: values.vertical.template!,
      vertical: values.vertical.vertical!,
    },
    comments: values.comments,
    preland: values.preland,
  }
  if (pwaData.subdomain?.address) pwaData.subdomain.address = pwaData.subdomain?.address.toLowerCase();
  if (!pwaData.subdomain?.address) {
    delete pwaData.subdomain;
  }

  if(pwaData.cloak.whitepage_type !== "wp") {
    delete pwaData.cloak.wordpress_theme;
  }

  console.log({ pwaData });

  try {
    if (id) {
      await updatePwa(id, pwaData, successUpdateData);
    } else {
      await sendPwa(pwaData, delaySetCreatePwaPending);
    }
  } catch (response: any) {
    if (response.response.data?.message === "[\"Limit of pwa's count for partner user\"]") {
      enqueueSnackbar('Вы достигли лимита по созданию PWA на сегодня. Лимит 5 PWA в день', { variant: 'error', style: { backgroundColor: '#D11A2B' } });
      return;
    }

    setCreatePwaPending(false)

    const formattedErrors = formatErrors(response.response.data);
    if (Object.keys(formattedErrors).length !== 0) {
      formikHelpers.setErrors(formattedErrors);
    } else {
      formikHelpers.setErrors({ serverError: response.message } as any);
    }


    const errorObj = response?.response?.data
    if (errorObj) {
      const errorKey = Object.keys(errorObj)[0];

      const foundTab = tabsArray.findIndex(tab => tab.value === errorKey);

      if (foundTab !== -1) {
        setPageContent(foundTab)
        enqueueSnackbar(`Исправьте ошибку во вкладке ${tabsArray[foundTab]?.tabName}`, { variant: 'error', style: { backgroundColor: '#D11A2B' } });
      }
      else {
        const jsonString = JSON.stringify(errorObj)

        enqueueSnackbar(`${jsonString}`, { variant: 'error', style: { backgroundColor: '#D11A2B' } });
      }
    }
  }
}



export async function formatFormData(pwaData: PwaDataGet) {
  const newFormData: PwaFormValues = {
    ...pwaData,
    subdomain: pwaData.subdomain,
    cloak: pwaData.cloak || pwaFormInitialValues.cloak,
    vertical: pwaData.vertical && {
      ...pwaData.vertical,
      preview_template: pwaData.vertical.preview_template.id
    },
    application: {
      ...pwaData.application,
      language: pwaData.application.language?.toString()
    },
  };
  if (newFormData.subdomain?.address) newFormData.subdomain.address = newFormData.subdomain?.address.toLowerCase();
  if (!newFormData.vertical) newFormData.vertical = pwaFormInitialValues.vertical;

  for (let i = 0; i < pwaData.comments.length; i++) {
    let avatarUrl = pwaData.comments[i].avatar;
    if (avatarUrl) {
      if (avatarUrl.startsWith('http:')) {
        avatarUrl = avatarUrl.replace('http:', 'https:');
      }
      const image = await axios.get(avatarUrl, { responseType: 'blob' });
      pwaData.comments[i].avatar = await convertImageToBase64(image.data as unknown as File);
    }
  }

  return newFormData;
}

export function validate(values: PwaFormValues) {
  const errors: any = {};

  if (
    values.cloak.whitepage_type === "gp"
    && !values.cloak.white_url?.includes("play.google.com/store/apps/details?id")
  ) {
    if (!errors.cloak) errors.cloak = {};
    errors.cloak.white_url = "Неправильная ссылка на приложение из Google Play";
  }

  if (
    values.cloak.whitepage_type === "ap"
    && !values.cloak.white_url?.includes("apps.apple.com/us/story/id")
  ) {
    if (!errors.cloak) errors.cloak = {};
    errors.cloak.white_url = "Неправильная ссылка на приложение из App Store";
  }

  if ((values.cloak.whitepage_type === "gp" || values.cloak.whitepage_type === "ap")
    && !values.cloak.white_url
  ) {
    errors.cloak.white_url = "Введите ссылку на вайт";
  }

  for (let i = 0; i < values.comments.length; i++) {
    if (
      values.comments[i]?.reply?.date
      && values.comments[i]?.date
      && new Date(values.comments[i].reply.date) < new Date(values.comments[i].date)
    ) {
      if (!errors.comments) {
        errors.comments = [];
        for (let j = 0; j < values.comments.length; j++) {
          errors.comments.push({ reply: {} });
        }
      }
      errors.comments[i].date = "Дата комментария должна быть равна или старше даты ответа";
      errors.comments[i].reply.date = "Дата ответа должна быть равна или меньше даты комментария";
    }
  }

  return errors;
}